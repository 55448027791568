import getLoginTemplateService from '~/src/pages/auth/login/styles/GetLoginTemplateService'
import StylesLoginServiceInterface from '~/src/pages/auth/login/styles/StylesLoginServiceInterface'
import { useLayout } from '~/src/hooks/layout/useLayout'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import useGetPageStyle from '~/src/hooks/layout/useGetPageStyle'
import { useEoValue } from '~/src/hooks/useEoState'
import { LAYOUT_STATE } from '~/src/stores/layout'

const useGetLoginTemplate = (): StylesLoginServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const pageStyle = useGetPageStyle('login')
  const layout = useEoValue(LAYOUT_STATE)

  useLayout('login')

  return getLoginTemplateService(pageStyle, muiTheme, layout)
}

export default useGetLoginTemplate
