import { SxProps } from '@mui/material'
import StylesLoginServiceInterface from '~/src/pages/auth/login/styles/StylesLoginServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesLoginServiceStyleOne extends TemplateServiceStyleBase implements StylesLoginServiceInterface {
  public getPageSx(): SxProps {
    return {
      my: 4,
      mt: `calc(var(--eo-safe-area-top) + ${this.muiTheme.spacing(4)})`,
      width: '40%',
      [this.muiTheme.breakpoints.only('xs')]: {
        width: '90%'
      },
      [this.muiTheme.breakpoints.only('sm')]: {
        width: '80%'
      },
      [this.muiTheme.breakpoints.only('md')]: {
        width: '65%'
      }
    }
  }

  public getCardContainerSx(): SxProps {
    return {
      p: 4,
      backgroundColor: 'container.main',
      color: 'container.contrastText'
    }
  }
}
