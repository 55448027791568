import { SxProps } from '@mui/material'
import StylesLoginServiceInterface from '~/src/pages/auth/login/styles/StylesLoginServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesLoginServiceStyleThree extends TemplateServiceStyleBase implements StylesLoginServiceInterface {
  public getPageSx(): SxProps {
    return {
      my: 4,
      mt: `calc(var(--eo-safe-area-top) + ${this.muiTheme.spacing(4)})`,
      width: '60vw',
      [this.muiTheme.breakpoints.only('xs')]: {
        width: '90vw'
      },
      [this.muiTheme.breakpoints.only('sm')]: {
        width: '80vw'
      },
      [this.muiTheme.breakpoints.only('md')]: {
        width: '65vw'
      },
      [this.muiTheme.breakpoints.up('xl')]: {
        width: '50vw',
        maxWidth: '800px'
      }
    }
  }

  public getCardContainerSx(): SxProps {
    return {
      p: 4,
      backgroundColor: 'container.main',
      color: 'container.contrastText'
    }
  }
}
