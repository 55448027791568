import StylesLoginServiceInterface from '~/src/pages/auth/login/styles/StylesLoginServiceInterface'
import StylesLoginServiceStyleOne from '~/src/pages/auth/login/styles/StylesLoginServiceStyleOne'
import StylesLoginServiceStyleThree from '~/src/pages/auth/login/styles/StylesLoginServiceStyleThree'
import StylesLoginServiceStyleTwo from '~/src/pages/auth/login/styles/StylesLoginServiceStyleTwo'
import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'
import { LayoutState } from '~/src/stores/layout'

const getLoginTemplateService = (style: TemplateEnum | null, muiTheme: CustomMuiTheme, layout: LayoutState): StylesLoginServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesLoginServiceStyleTwo(muiTheme, undefined, layout)
    case TemplateEnum.STYLE_THREE:
      return new StylesLoginServiceStyleThree(muiTheme, undefined, layout)
    default:
      return new StylesLoginServiceStyleOne(muiTheme, undefined, layout)
  }
}

export default getLoginTemplateService
